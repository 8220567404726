import React from 'react'
import img from '../img/me.jpg'
import { useTranslation } from 'react-i18next'
import In from '../img/ln.png'
import Im from '../img/Im.png'

export const Home = () => {

  const { t } = useTranslation()

  return (
    <div className="max-w-screen-lg px-10 md:px-10 mx-auto">
      <section className="flex flex-col lg:flex-row justify-between gap-4 sm:gap-4 md:gap-4">
        <div className="xl:w-1/2 flex flex-col justify-between">
          <div className="sm:text-center lg:text-left lg:py-12 xl:py-24">
            <p className="text-slate-700 md:text-lg xl:text-xl font-semibold mb-4 md:mb-6">{t('home.title')}</p>
            <h1 className="text-black-800 text-3xl sm:text-4xl md:text-5xl font-bold mb-8 md:mb-12">{t('home.name')}</h1>
            <div className="flex flex-col sm:flex-row sm:justify-center lg:justify-start gap-2.5">
              <a href="https://hybridexcuse.com/" target="_blank" className="inline-block bg-slate-700 hover:bg-slate-600 active:bg-slate-700 focus-visible:ring ring-slate-300 text-white text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3" rel="noreferrer">{t('home.btn1')}</a>
              <a href="https://ruomi.io/" target="_blank" className="inline-block bg-gray-100 text-black hover:bg-gray-300 focus-visible:ring ring-slate-300 text-gray-500 active:text-gray-700 text-sm md:text-base font-semibold text-center rounded-lg outline-none transition duration-100 px-8 py-3" rel="noreferrer">{t('home.btn2')}</a>
            </div>
          </div>
          <div className="flex justify-center lg:justify-start items-center gap-4 mt-8 sm:mt-16">
            <span className="text-gray-400 text-sm sm:text-base font-semibold tracking-widest uppercase">{t('home.some')}</span>
            <span className="w-12 h-px bg-gray-200"></span>
            <div className="flex gap-4">
              <a href="https://www.linkedin.com/in/dariahannonen/" target="_blank" className="h-4 text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100" rel="noreferrer">
                <img src={In} className="h-5" alt="LinkedIn logo link" />
              </a>
              <a href="https://www.instagram.com/aia.aoe/" target="_blank" className="text-gray-400 hover:text-gray-500 active:text-gray-600 transition duration-100" rel="noreferrer">
                <img src={Im} className="h-5" alt="Instagram logo link" />
              </a>
            </div>
          </div>
        </div>
        <div className="xl:w-5/12 h-auto lg:h-auto bg-gray-100 overflow-hidden shadow-sm rounded-md">
          <img src={img} loading="lazy" alt="Daria's photo" className="w-full h-full object-cover object-center" />
        </div>
      </section>
    </div>
  )
}
