import React from 'react'

export const About = () => {

  return (
    <>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-3xl text-center sm:text-center mt-2">
          <div>
            <h1 className="font-bold tracking-tight sm:text-center mb-10 text-2xl">
								ABOUT
            </h1>
            <div className='grid justify-items-center mx-auto max-w-screen-md mb-10'>
              <div className="flex flex-wrap mb-0 text-sm text-slate-700 sm:mb-0 text-xl">
                <p>Aliquam ac velit ut odio malesuada venenatis. Donec a felis in dui feugiat elementum. Integer consequat facilisis mauris, nec sollicitudin leo elementum. </p>
              </div>
            </div>
            <div className="shadow-sm mb-4">
              <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" src="https://images.pexels.com/photos/8219393/pexels-photo-8219393.jpeg?auto=compress&cs=tinysrgb&w=600" />
                <div className="flex-grow sm:p-8 text-center md:text-start">
                  <h2 className="title-font font-medium text-lg text-gray-900">Nastia Vikto</h2>
                  <h3 className="text-gray-500 mb-3">Sister 1 <a href='' className="text-blue-500">LinkedIn</a></h3>
                  <p className="mb-4">Pellentesque consectetur eros id eros faucibus aliquet. Duis risus erat, luctus ac augue id, fringilla eleifend purus.</p>
                </div>
              </div>
            </div>
            <div className="shadow-sm">
              <div className="h-full flex sm:flex-row flex-col items-center sm:justify-start justify-center text-center sm:text-left">
                <div className="flex-grow sm:p-8 text-center md:text-end">
                  <h2 className="title-font font-medium text-lg text-gray-900">Daria Hannonen</h2>
                  <h3 className="text-gray-500 mb-3">Sister 2 <a href='' className="text-blue-500">LinkedIn</a></h3>
                  <p className="mb-4">Pellentesque consectetur eros id eros faucibus aliquet. Duis risus erat, luctus ac augue id, fringilla eleifend purus.</p>
                </div>
                <img alt="team" className="flex-shrink-0 rounded-lg w-48 h-48 object-cover object-center sm:mb-0 mb-4" src="https://images.pexels.com/photos/8219394/pexels-photo-8219394.jpeg?auto=compress&cs=tinysrgb&w=600" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
