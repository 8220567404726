import React from 'react'
import { Link } from 'react-router-dom'
import ln from '../img/ln.png'

export const Products = () => {

  return (
    <>
      <div className="relative px-6 lg:px-8">
        <div className="container px-5 py-10 mx-auto">
          <div className="flex flex-col text-center w-full mb-12">
            <h1 className="font-bold tracking-tight sm:text-center mb-10 text-2xl">Products</h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-10">Quisque scelerisque arcu et purus commodo dapibus. Fusce leo tellus, pharetra vitae urna quis, sollicitudin bibendum nulla. Vivamus tellus massa, eleifend at sem a, consequat commodo nulla. Aliquam at neque justo. Donec posuere dapibus turpis non elementum. Ut egestas congue nibh vitae gravida.</p>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base mb-10">Who are you?</p>
            <div className='grid justify-items-center mx-auto max-w-screen-md mb-4'>
              <div className="flex flex-wrap mb-0 text-sm text-slate-700 sm:mb-0 ">
                {(<Link to='/products/individual' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">Individual</Link>)}
                {(<Link to='/products/business' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">Business</Link>)}
                {(<Link to='/products/robot' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">Robot</Link>)}
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  )
}