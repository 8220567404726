import React from 'react'
import Logo from '../img/logo.png'
import { Link } from 'react-router-dom'
import Container from '../components/Container'
import { Disclosure } from '@headlessui/react'

export const Navbar = () => {
  return (
    <Container>
      <div>
        <Disclosure>
          <div className="flex flex-wrap justify-between md:gap-10 md:flex-nowrap">
            <div className="flex-col items-center justify-start order-1 hidden w-full md:flex md:flex-row md:justify-end md:w-auto md:order-none md:flex-1">
              <Link to='/' className="mr-4 hover:underline hover:text-slate-600 md:mr-6">
                <a className="px-5 py-2 text-sm font-medium text-slate-600 hover:text-red-500"></a>
              </Link>
            </div>
            <div className="flex justify-center w-full md:w-auto">
              <Link to='/' className='flex items-center'>
                <a className="flow-root">
                  <img src={Logo} alt="Ruomi Logo" className="h-8 sm:h-10 mr-2" />
                </a>
              </Link>
            </div>
            <div className="flex flex-col items-center justify-start order-2 hidden w-full md:flex md:flex-row md:w-auto md:flex-1 md:order-none">
              <Link to='/' >
                <a className="text-sm font-medium text-slate-600 hover:text-red-500"></a>
              </Link>
            </div>
          </div>
          <Disclosure.Panel>
            <div className="items-center justify-center order-2 w-full md:hidden mt-4 mb-4 flex gap-4">
              <Link to='/' >
                <a className="text-sm font-medium text-slate-600 hover:text-red-500"></a>
              </Link>
              <Link to='/' >
                <a className="text-sm font-medium text-slate-600 hover:text-red-500"></a>
              </Link>
            </div>
          </Disclosure.Panel>
          <hr className="my-2 border-slate-200 sm:mx-auto lg:my-2" />
        </Disclosure>
      </div>
    </Container>
  )
}
